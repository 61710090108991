import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  useParams, useSearchParams, useNavigate,
} from 'react-router-dom';

import {
  Box, Button, Card, CircularProgress, Grid,
  Snackbar, Stack, Switch, Typography,
} from '@mui/material';
import moment from 'moment/moment';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicator from '@mui/icons-material/DragIndicator';

import {
  DocPrepProps,
  PresignedUrlResponse,
} from './DocPrep.types';
import OliMaterialTable from '../OliMaterialTable/OliMaterialTable';
import UserContext from '../../context/UserContext';
import OliAxios from '../../api/util/OliAxios';
import { getConfig } from '../../api/util/getConfig';
import {
  Data, DownloadParams, ErrorNotFound,
} from '../FileTransfer/FileTransfer.types';
import { Action } from '../OliMaterialTable/OliMaterialTable.types';
import { downloadFile } from '../FileTransfer/List/List.api';
import analytics from '../../Analytics';
import {
  mergePDF, deletePDF, getPresignedUrl,
} from './DocPrepWorkflow.api';
import renderPDFPreview from './DocPrepPreview';

const MERGED_TEMP_BUCKET = process.env.REACT_APP_MERGED_TEMP_BUCKET;
const SUPPORTING_FILE_BUCKET = process.env.REACT_APP_SUPPORTING_BUCKET;

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((
  props,
  ref,
) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

/* eslint-disable jsx-a11y/label-has-associated-control */
// eslint-disable-next-line max-lines-per-function
const DocPrepExhibits: React.FunctionComponent<DocPrepProps> = ({
  loading, setLoading, docPrepData, setActiveStep,
}) => {
  const navigate = useNavigate();
  const {
    docId,
  } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [filename, setFilename] = useState<string>();
  const [presignedUrl, setPresignedURL] = useState<string>();

  const [documents, setDocuments] = useState<Data[]>([] as Data[]);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(true);

  const [exhibits, setExhibits] = useState<Data[]>([] as Data[]);
  const [labelingMode, setLabelingMode] = useState<'letter' | 'number'>('letter');

  const [dragging, setDragging] = useState<{ current: number }>({ current: 0 });
  const [error, setError] = useState<Error>();
  const [openFail, setOpenFail] = useState(false);

  const token = useContext(UserContext)?.token;

  const handleSuccess = (result: Data[] | ErrorNotFound): void => {
    setLoadingDocuments(false);
    if (result instanceof Array) {
      setDocuments(result);
    }
  };

  const getDocuments = async (): Promise<void> => {
    if (!token || !docPrepData.doc) return;

    setLoadingDocuments(true);
    const config = getConfig(token);
    const url = '/fileTransfer/list';
    const params = {
      matter_uuid: docPrepData.matter_uuid,
    };
    await OliAxios.get(url, {
      ...config, params,
    }).then((result) => handleSuccess(result.data));
  };

  useEffect(() => {
    if (token) {
      getDocuments();
    }
  }, [docPrepData.matter_uuid]);

  const formatData = (filedata: Data[] = []): Data[] => filedata.map((element) => ({
    ...element, uploaded: moment(element.uploaded).format('MM/DD/YYYY'),
  }));

  const generateEmptyTemplate = async (): Promise<void> => {
    if (!token || !docPrepData.doc) return;

    const params = {
      templateDocx: docPrepData.doc?.template_reference,
      mergedData: docPrepData.doc?.original_data,
      outputPdf: docPrepData.doc?.s3_key,
    };

    const result = await mergePDF(token, params);

    if (result) {
      setLoadingDocuments(false);
      setFilename(result.data.s3key);
    }
  };

  useEffect(() => {
    setActiveStep(0);

    generateEmptyTemplate();
  }, [docPrepData]);

  const fetchPresignedUrl = async (): Promise<void> => {
    if (!token || !filename) return;

    const resp = await getPresignedUrl(token, process.env.REACT_APP_MERGED_TEMP_BUCKET, filename);

    if (!resp) return;

    setPresignedURL(resp?.data.url);
  };

  useEffect(() => {
    fetchPresignedUrl();
  }, [filename]);

  const viewFile = (rowData: Data): void => {
    const params: DownloadParams = {
      path: rowData.path,
      fileID: rowData.id,
      isDownload: false,
    };

    analytics.track('exhibit-viewed');

    downloadFile(token, params);
  };

  const handleAddExhibit = (rowData: Data): void => {
    searchParams.set('exhibits', JSON.stringify([
      ...exhibits,
      {
        ...rowData,
        uniqueKey: `${rowData.id}-${Date.now()}`,
        bucket: SUPPORTING_FILE_BUCKET as string,
        label: `Exhibit ${String.fromCharCode(65 + exhibits.length)}`,
      },
    ]));

    analytics.track('exhibit-added');

    setSearchParams(searchParams, { replace: true });
  };

  const handleUploadSuccess = (key: string, e: ChangeEvent<HTMLInputElement>): void => {
    const { files } = e.target;
    if (!files) return;

    const rowData: Data = {
      id: key,
      name: files[0].name,
      description: 'Uploaded Exhibit',
      tags: '',
      path: key,
      bucket: MERGED_TEMP_BUCKET as string,
    };

    searchParams.set('exhibits', JSON.stringify([
      ...exhibits,
      rowData,
    ]));
    setSearchParams(searchParams, { replace: true });

    e.target.value = '';
  };

  useEffect(() => {
    const urlExhibits = searchParams.get('exhibits');
    if (urlExhibits) {
      setExhibits(JSON.parse(urlExhibits));
    }

    const urlLabelingMode = searchParams.get('labelingMode');
    if (urlLabelingMode) {
      setLabelingMode(urlLabelingMode);
    }
  }, [searchParams]);

  const actions: Action[] = [
    {
      icon: () => <VisibilityIcon />,
      tooltip: 'View File',
      onClick: (_e, rowData) => viewFile(rowData as Data),
    },
    {
      icon: () => <AddIcon data-testid="add-icon" />,
      tooltip: 'Add to Exhibits',
      onClick: (_e, rowData) => handleAddExhibit(rowData as Data),
    },
  ];

  const handleDelete = async (): Promise<void> => {
    setLoading(true);

    const params = {
      id: docPrepData?.doc?.id as string,
    };

    await deletePDF(token, params);

    analytics.track('doc-prep-deleted');

    setLoading(false);

    navigate('/doc-prep/');
  };

  const handleLabelingStyleChange = (e: React.ChangeEvent): void => {
    if (e.target.checked) {
      searchParams.set('labelingMode', 'letter');
    } else {
      searchParams.set('labelingMode', 'number');
    }

    setSearchParams(searchParams, { replace: true });
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, index: number): void => {
    setDragging({ current: index });
    e.dataTransfer.effectAllowed = 'move';
    const target = e.target as HTMLDivElement;
    target.style.opacity = '0.4';
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>): void => {
    const target = e.target as HTMLDivElement;
    target.style.opacity = '1';
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>, index: number): void => {
    e.preventDefault();
    const draggedItem = exhibits[dragging.current];
    if (dragging.current === index) return;
    const dragExhibits = exhibits.filter((_, idx) => idx !== dragging.current);
    dragExhibits.splice(index, 0, draggedItem);
    setDragging({ current: index });

    analytics.track('exhibit-reordered');

    searchParams.set('exhibits', JSON.stringify(dragExhibits));
    setSearchParams(searchParams, { replace: true });
  };

  const handleDeleteExhibit = (index: number): void => {
    analytics.track('exhibit-delete');

    exhibits.splice(index, 1);
    searchParams.set('exhibits', JSON.stringify(exhibits));
    setSearchParams(searchParams, { replace: true });
  };

  const handleUploadError = (er: Error, e: ChangeEvent<HTMLInputElement>): void => {
    setOpenFail(true);
    setError(er);
    e.target.value = '';
  };

  const handleNavigateNext = (): void => {
    const path = {
      pathname: `/doc-prep/packets/${docId}/data-input/`,
      search: searchParams.toString(),
    };

    navigate(path);
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>): Promise<string | undefined> => {
    if (!token) return undefined;
    const { files } = event.target;
    if (!files) return undefined;

    const file = files[0];

    const params = {
      bucket: MERGED_TEMP_BUCKET,
      key: `${file.name}`,
    };
    const response: PresignedUrlResponse = await OliAxios.get(`${process.env.REACT_APP_API_URL}/documents/upload`, {
      ...getConfig(token),
      params,
    });

    const formData = new FormData();

    Object.entries(response.data.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append('Content-Type', file.type);

    //  Make sure to pass data first otherwise it'll throw an error like
    // Bucket POST must contain a field named 'key'.  If it is specified, please check the order of the fields.
    formData.append('file', file, file.name);

    await OliAxios.post(response.data.uploadURL, formData);

    analytics.track('exhibit-upload');

    return response.data.key;
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    handleFileChange(e)
      .then((key) => { if (key) handleUploadSuccess(key, e); })
      .catch((er) => handleUploadError(er, e));
  };

  const closeSnackBar = (): void => {
    setOpenFail(false);
  };

  const calculateExhibitIndex = (index: number): string => {
    if (labelingMode === 'number') {
      return `${index + 1}`;
    }

    return `${String.fromCharCode('A'.charCodeAt(0) + index)}`;
  };

  const columns = [
    {
      title: 'Tags',
      field: 'description',
      headerStyle: {
        fontWeight: 'bold',
      },
    },
    {
      title: 'Name',
      field: 'name',
      headerStyle: {
        fontWeight: 'bold',
      },
      cellStyle: {
        wordBreak: 'break-all',
      },
    },
    {
      title: 'Uploaded On',
      field: 'uploaded',
      headerStyle: {
        fontWeight: 'bold',
      },
    },
    {
      title: 'Creditor Create Date',
      field: 'sor_creation_date',
      headerStyle: {
        fontWeight: 'bold',
      },
    },
  ];

  const renderDocumentsList = (): JSX.Element => (
    <OliMaterialTable
      actions={actions}
      columns={columns}
      loading={loadingDocuments}
      data={formatData(documents)}
      pagesize={5}
      selectable={false}
      filter
    />
  );

  const renderSelectedExhibits = (): JSX.Element => (
    <Card sx={{
      padding: '10px',
    }}
    >
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={6}>
          <h2>Selected Exhibits</h2>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <span>
            <input
              accept=".pdf"
              id="file"
              data-testid="upload-input"
              type="file"
              onChange={onFileChange}
              hidden
            />
            <label htmlFor="file">
              <Button color="secondary" variant="contained" component="span">
                Upload Exhibit
              </Button>
            </label>
          </span>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between">
        <h6>
          Uploaded files must be .pdf and maximum file size is 15MB
        </h6>
      </Grid>
      <div>
        {exhibits.map((item, index) => (
          <Card
            draggable
            onDragStart={(e) => handleDragStart(e, index)}
            onDragEnd={handleDragEnd}
            onDragOver={(e) => handleDragOver(e, index)}
            key={item.uniqueKey as string}
            data-testid={`exhibit-card-${item.id}-${index}`}
            sx={{
              padding: '10px',
              margin: '10px',
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                minWidth: 0,
                position: 'relative',
              }}
            >
              <Grid
                item
                xs={2}
                sx={{
                  width: 'auto',
                  flexShrink: 0,
                  zIndex: 1,
                }}
              >
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                }}
                >
                  <DragIndicator sx={{ cursor: 'grab' }} />
                  <Button data-testid={`exhibit-delete-${item.id}-${index}`} onClick={() => handleDeleteExhibit(index)}><DeleteIcon /></Button>
                </Box>
              </Grid>
              <Grid
                item
                xs={10}
                sx={{
                  flexGrow: 1,
                  minWidth: 0,
                  width: 'auto',
                  pl: 2,
                  '& span': {
                    display: 'block',
                    overflow: 'hidden',
                    textOverride: 'ellipsis',
                    whiteSpace: 'nowrap',
                  },
                }}
              >

                {`Exhibit ${calculateExhibitIndex(index)}`}
                {': '}
                {item.description}
                {' - '}
                {item.name}
              </Grid>
            </Grid>
          </Card>
        ))}
      </div>
    </Card>
  );

  return (
    <>
      <>
        <Grid container direction="row" justifyContent="space-between">
          <br />
          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs={6} sx={{ paddingRight: '1.9%' }}>
              {renderPDFPreview(presignedUrl, { view: 'FitH' })}
            </Grid>
            <Grid item xs={6}>

              <Grid container direction="row" justifyContent="end" alignItems="center">
                <Grid item xs={2}>
                  <h4>Labeling Style</h4>
                </Grid>
                <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                  <Typography>Numbers</Typography>
                  <Switch data-testid="labeling-style" checked={labelingMode === 'letter'} onChange={handleLabelingStyleChange} />
                  <Typography>Letters</Typography>
                </Stack>
              </Grid>

              {renderDocumentsList()}
              {renderSelectedExhibits()}
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-end" sx={{ marginTop: '1rem' }}>
          <Snackbar
            open={openFail}
            onClose={closeSnackBar}
          >
            <Alert onClose={closeSnackBar} severity="error" sx={{ width: '100%' }}>
              Something has gone wrong. (
              {error?.message}
              )
            </Alert>
          </Snackbar>
          <Button
            color="error"
            variant="contained"
            onClick={() => handleDelete()}
            sx={{ mr: 1 }}
          >
            Delete
          </Button>
          <Button
            color="inherit"
            onClick={() => navigate('/doc-prep/')}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleNavigateNext}
            disabled={loading}
          >
            {loading && (
              <CircularProgress
                size={20}
                sx={{
                  marginRight: '0.5rem',
                }}
              />
            )}
            Next
          </Button>
        </Grid>
      </>
    </>
  );
};

export default DocPrepExhibits;
