import {
  Outlet,
} from 'react-router-dom';
import Header from 'components/Header/Header';
import {
  Box,
  Button, Checkbox,
  Container, FormControl, FormControlLabel, InputLabel, MenuItem, Select,
} from '@mui/material';
import React, {
  useState,
} from 'react';
import OliTextField from '../Shared/OliTextField/OliTextField';
import { AccountData } from './MyAccount.types';
import OliDateField from '../Shared/OliDateField/OliDateField';
import StateSelect from '../Shared/StateSelect/StateSelect';

const MyAccount: React.FunctionComponent = () => {
  const [accountData, setAccountData] = useState<AccountData | null>({
    notary_expires: false,
  });
  return (
    <Container maxWidth={false}>
      <Header text="My Account" period />
      <div>
        <div style={{
          maxHeight: '750px',
          width: '500px',
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: 10,
          border: '1px solid gray',
        }}
        >
          <h3>Profile</h3>
          <Box
            alignItems="flex-end"
            component="form"
            noValidate
            sx={{
              display: 'grid',
              gap: 2,
            }}
            data-testid="notarization-input-fields"
          >
            <React.Fragment key="name">
              <OliTextField
                value="John Doe"
                label="Name"
                disabled
              />
            </React.Fragment>
            <React.Fragment key="email">
              <OliTextField
                value="jdoe@wellsfargo.com"
                label="E-Mail"
                disabled
              />
            </React.Fragment>
            <React.Fragment key="signature">
              <span>
                <input
                  accept=".pdf"
                  id="signture"
                  data-testid="upload-signature"
                  type="file"
                  hidden
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="file">
                  <Button color="secondary" variant="contained" component="span">
                    Upload Signature
                  </Button>
                </label>
              </span>
            </React.Fragment>
          </Box>
        </div>

        <div style={{
          maxHeight: '750px',
          width: '500px',
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: 10,
          border: '1px solid gray',
          marginTop: 15,
        }}
        >
          <h3>Notarization</h3>
          <Box
            alignItems="flex-end"
            component="form"
            noValidate
            sx={{
              display: 'grid',
              gap: 2,
            }}
            data-testid="notarization-input-fields"
          >
            Journal
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel id="select-label" required>Country</InputLabel>
              <Select
                labelId="country"
                id="country"
                value="US"
                label="Country"
                variant="standard"
                disabled
                required
                data-testid="country-select"
              >
                <MenuItem value="US" selected>US</MenuItem>
              </Select>
            </FormControl>
            <StateSelect
              label="Jurisdiction"
              value={accountData?.jurisdiction as string}
              loading={false}
              handleChange={(event) => setAccountData({
                ...accountData,
                jurisdiction: event.target.value,
              })}
            />

            <OliTextField
              value={accountData?.county || ''}
              label="County"
              disabled={false}
              handleChange={(event) => setAccountData({
                ...accountData,
                county: event.target.value,
              })}
            />
            <OliTextField
              value={accountData?.commission_number || ''}
              label="Commission Number"
              disabled={false}
              handleChange={(event) => setAccountData({
                ...accountData,
                commission_number: event.target.value,
              })}
            />
            <OliDateField
              value={accountData?.expiration_date || ''}
              label="Expiration Date"
              disabled={accountData?.notary_expires as boolean}
              handleChange={(event) => setAccountData({
                ...accountData,
                expiration_date: event?.format('MM/DD/YYYY'),
              })}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  data-testid="expiration-checkbox"
                  checked={accountData?.notary_expires as boolean}
                  onChange={(event) => setAccountData({
                    ...accountData,
                    notary_expires: event.target.checked,
                  })}
                  name="exampleCheckbox"
                  color="primary"
                />
              )}
              label="My Notary doesn't expire"
            />
            <span>
              <input
                accept=".pdf"
                id="file"
                data-testid="upload-input"
                type="file"
                hidden
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="file">
                <Button color="secondary" variant="contained" component="span">
                  Upload Notary Seal
                </Button>
              </label>
            </span>
          </Box>
        </div>
        <Outlet />
      </div>
    </Container>
  );
};

export default MyAccount;
