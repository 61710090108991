import React, {
  useContext, useEffect, useState,
} from 'react';

import {
  useParams, useNavigate,
} from 'react-router-dom';

import {
  Box, Button, CircularProgress, Grid,
} from '@mui/material';
import {
  DocPrepProps,
} from './DocPrep.types';
import {
  getPresignedUrl,
  approvePDF,
} from './DocPrepWorkflow.api';
import renderPDFPreview from './DocPrepPreview';
import UserContext from '../../context/UserContext';
import analytics from '../../Analytics';

const DocPrepFinalize: React.FunctionComponent<DocPrepProps> = ({
  loading, setLoading, docPrepData, setActiveStep,
}) => {
  const token = useContext(UserContext)?.token;

  const navigate = useNavigate();
  const {
    filename,
  } = useParams();

  const [presignedUrl, setPresignedURL] = useState<string>();

  const fetchPresignedUrl = async (): Promise<void> => {
    if (!token) return;

    const resp = await getPresignedUrl(token, process.env.REACT_APP_MERGED_TEMP_BUCKET, filename);

    if (!resp) return;

    setPresignedURL(resp?.data.url);
  };

  useEffect(() => {
    setActiveStep(2);

    fetchPresignedUrl();
  }, [filename]);

  const handleFinish = async (): Promise<void> => {
    const params = {
      tgaUuid: docPrepData.doc?.id,
    };
    setLoading(true);
    await approvePDF(token, params);

    analytics.track('doc-prep-approved');

    setLoading(false);
    navigate('/doc-prep');
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <br />
        <Box
          alignItems="flex-end"
          component="form"
          noValidate
          sx={{
            width: '100%',
          }}
        >
          {
            renderPDFPreview(presignedUrl, {
              zoom: '100',
              thumbnails: true,
            })
          }
        </Box>
      </Grid>
      <Grid container direction="row" justifyContent="flex-end" sx={{ marginTop: '1rem' }}>
        <Button
          color="inherit"
          onClick={() => navigate(-1)}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Button variant="contained" color="secondary" onClick={handleFinish} disabled={loading}>
          {loading && (
            <CircularProgress
              size={20}
              sx={{
                marginRight: '0.5rem',
              }}
            />
          )}
          Finish
        </Button>
      </Grid>
    </>
  );
};

export default DocPrepFinalize;
