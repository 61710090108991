import React, {
  useContext, useEffect, useState,
} from 'react';
import {
  useParams, useSearchParams, useNavigate,
} from 'react-router-dom';
import {
  Box, Button, CircularProgress, Grid,
} from '@mui/material';
import moment, { Moment } from 'moment/moment';
import OliTextField from '../Shared/OliTextField/OliTextField';
import OliDateField from '../Shared/OliDateField/OliDateField';
import {
  Data,
} from '../FileTransfer/FileTransfer.types';
import {
  DataField, DocPrepDataInputProps, CombineResponse,
} from './DocPrep.types';
import {
  mergePDF, concatenatePDFs, getPresignedUrl,
} from './DocPrepWorkflow.api';
import renderPDFPreview from './DocPrepPreview';
import UserContext from '../../context/UserContext';
import analytics from '../../Analytics';

const MERGED_TEMP_BUCKET = process.env.REACT_APP_MERGED_TEMP_BUCKET;

/* eslint-disable max-lines-per-function */
const DocPrepDataInput: React.FunctionComponent<DocPrepDataInputProps> = ({
  loading, setLoading, docPrepData, setActiveStep,
}) => {
  useEffect(() => setActiveStep(1));

  const token = useContext(UserContext)?.token;
  const { docId } = useParams();
  const [formData, setFormData] = useState<Record<string, unknown>>({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [labelingMode, setLabelingMode] = useState<'number' | 'letter'>('letter');

  const [presignedUrl, setPresignedURL] = useState<string>();
  const [documentLoading, setDocumentLoading] = useState<boolean>();

  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [documentRequested, setDocumentRequested] = useState<boolean>(false);

  const [exhibits, setExhibits] = useState<Data[]>([] as Data[]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!docPrepData.doc) return;

    const urlFormData = searchParams.get('formData');

    const fieldValues: Record<string, unknown> = JSON.parse(urlFormData || '{}') as Record<string, unknown>;

    docPrepData.doc.data_fields.forEach((df: DataField): void => {
      if (!fieldValues[df.key]) {
        let initialValue = docPrepData.doc.original_data[df.key] || df.defaultvalue;

        if (df.type === 'date' && initialValue) {
          initialValue = moment(initialValue).format('MM/DD/YYYY');
        }

        fieldValues[df.key] = initialValue;
      }
    });

    setFormData(fieldValues);

    const urlExhibits = searchParams.get('exhibits');
    if (urlExhibits) {
      setExhibits(JSON.parse(urlExhibits));
    }

    const urlLabelingMode = searchParams.get('labelingMode');
    if (urlLabelingMode === 'number' || urlLabelingMode === 'letter') {
      setLabelingMode(urlLabelingMode);
    }

    setDataLoaded(true);
  }, [searchParams, docPrepData.doc]);

  const updateField = (key: string, value: string | undefined): void => {
    searchParams.set('formData', JSON.stringify({
      ...formData,
      [key]: value,
    }));
    setSearchParams(searchParams, { replace: true });

    analytics.track('doc-prep-data-input');
  };

  const isValid = (): boolean => {
    let valid = true;
    docPrepData.doc?.data_fields.forEach((df) => {
      if (df.required && (formData[df.key] === '' || !formData[df.key])) {
        valid = false;
      }
    });
    return valid;
  };

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>, key: string): void => {
    updateField(key, event.target.value);
  };

  const handleDateFieldChange = (event: Moment | null, key: string): void => {
    updateField(key, event?.format('MM/DD/YYYY'));
  };

  const concatenateFinalPDF = async (): Promise<CombineResponse> => {
    const params = {
      matter_reference: docPrepData.doc?.matter_reference,
      labelingMode,
      s3Objects: [
        {
          bucket: MERGED_TEMP_BUCKET,
          tga_uuid: docId,
          isExhibit: false,
        },
        ...exhibits.map((exhibit) => ({
          bucket: exhibit.bucket,
          key: exhibit.path,
          isExhibit: true,
        })),
      ],
    };
    const result = await concatenatePDFs(token, params);

    if (!result || result.status !== 200) {
      console.error('error concating. result {}', result);
      throw new Error('There was an unexpected problem generating the PDF');
    }

    const presignedResult = await getPresignedUrl(token, process.env.REACT_APP_MERGED_TEMP_BUCKET, result.data.key);

    if (presignedResult && presignedResult.status === 200) {
      setDocumentLoading(false);
      setPresignedURL(presignedResult.data.url);

      return result.data;
    }

    console.error('error merging document. result {}', result);
    throw new Error('There was an unxepected problem generating the PDF');
  };

  const merge = async (): Promise<undefined | CombineResponse> => {
    if (!searchParams || !docPrepData || !formData) return undefined;

    const params = {
      templateDocx: docPrepData.doc?.template_reference,
      mergedData: formData,
      outputPdf: docPrepData.doc?.s3_key,
    };

    setDocumentRequested(true);

    await mergePDF(token, params);

    return concatenateFinalPDF();
  };

  useEffect(() => {
    if (!dataLoaded || documentRequested || !docPrepData.doc) return;

    setDocumentLoading(true);

    merge();
  }, [docPrepData, dataLoaded, documentRequested]);

  const handleNavigateNext = async (): Promise<void> => {
    setLoading(true);

    const final = await merge();

    setLoading(false);

    navigate(`/doc-prep/packets/${docId}/finalize/${encodeURIComponent(final.key)}`);
  };

  const renderPreviewSection = (): JSX.Element => {
    if (!documentLoading) {
      return renderPDFPreview(presignedUrl, { view: 'FitH' });
    }

    return (
      <Box sx={{
        height: '100%',
        display: 'grid',
        alignContent: 'center',
        justifyContent: 'center',
      }}
      >
        <CircularProgress
          size={50}
        />
      </Box>
    );
  };

  const renderFields = (): JSX.Element[] => {
    if (!docPrepData.doc) return [<></>];
    return docPrepData.doc.data_fields.filter((df) => df.label !== '').map((df) => {
      if (df.type === 'string') {
        return (
          <React.Fragment key={df.key}>
            <OliTextField
              value={formData[df.key] as string}
              label={df.label}
              required={df.required}
              error={!formData[df.key] && df.required}
              handleChange={(event) => handleTextFieldChange(event, df.key)}
              disabled={loading}
            />
          </React.Fragment>
        );
      }
      if (df.type === 'date') {
        return (
          <React.Fragment key={df.key}>
            <OliDateField
              value={formData[df.key] as string}
              label={df.label}
              handleChange={(event) => handleDateFieldChange(event, df.key)}
              disabled={loading}
              error={!formData[df.key] && df.required}
              required={df.required}
            />
          </React.Fragment>
        );
      }

      return <React.Fragment key={df.key} />;
    });
  };

  const renderFieldsBox = (): JSX.Element => (
    (
      <>
        <br />
        <div style={{
          maxHeight: '750px',
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: 5,
        }}
        >
          <Box
            alignItems="flex-end"
            component="form"
            noValidate
            sx={{
              display: 'grid',
              gap: 2,
            }}
            data-testid="data-input-fields"
          >
            {renderFields()}
          </Box>
        </div>
        <Grid container direction="row" justifyContent="flex-end" sx={{ marginTop: '1rem' }}>
          <Button
            color="inherit"
            onClick={() => navigate(-1)}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={!isValid() || loading}
            onClick={handleNavigateNext as () => void}
          >
            {loading && (
              <CircularProgress
                size={20}
                sx={{
                  marginRight: '0.5rem',
                }}
              />
            )}
            Next
          </Button>
        </Grid>
      </>
    )
  );

  return (
    <Grid container direction="row" justifyContent="space-between">
      <br />
      <Grid container direction="row" justifyContent="space-between">
        <Grid item xs={6} sx={{ paddingRight: '1.9%' }}>
          {renderPreviewSection()}
        </Grid>
        <Grid item xs={6}>
          {renderFieldsBox()}
        </Grid>
      </Grid>
    </Grid>
  );
};
/* eslint-enable max-lines-per-function */

export default DocPrepDataInput;
