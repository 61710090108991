import {
  Paper,
  Theme,
  useTheme,
} from '@mui/material';
import React from 'react';
import MaterialTable from '@material-table/core';
import { IMaterialTableProps } from './OliMaterialTable.types';

const SORT_LABEL_ROOT = '& .MuiTableSortLabel-root';

interface ExtendedTheme extends Theme {
  palette: Theme['palette'] & {
    custom: {
      headerHover: string;
    };
  };
}

const OliMaterialTable: React.FunctionComponent<IMaterialTableProps> = (props) => {
  const {
    data,
    columns,
    actions,
    loading,
    title,
    pagesize,
    search,
    selectable,
    filter,
    tableRef,
    header,
    pageSizeOptions,
    maxBodyHeight,
    onRowClick,
  } = props;

  const theme = useTheme<ExtendedTheme>();

  const showSelection = actions && actions?.length > 0 && selectable !== false;
  const tableTitle = title || '';
  const tablePageSize = pagesize || 20;
  const tablePageSizeOptions = pageSizeOptions || [5, 10, 20, 50, 100] as number[];

  const options = {
    selection: showSelection,
    search: search || false,
    pageSize: tablePageSize,
    pageSizeOptions: tablePageSizeOptions,
    filtering: filter || false,
    header: header === undefined,
    toolbar: showSelection || tableTitle !== '',
    maxBodyHeight: maxBodyHeight || 'calc(100vh - 280px)',
    onRowClick: onRowClick || null,
    padding: 'dense',
    rowStyle: {
      fontSize: theme.typography.body2.fontSize,
      padding: (theme.components?.MuiTableCell?.styleOverrides?.root as { padding?: string })?.padding ?? '16px',
    },
    headerStyle: {
      position: 'sticky',
      top: 0,
      zIndex: 11,
    },
    fixedColumns: {
      left: 0,
      right: 0,
    },
    stickyHeader: true,
    filterRowStyle: {
      height: '20px',
    },
    filterCellStyle: {
      padding: '1px',
    },
  };

  /* eslint-disable @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access */
  return (
    <Paper sx={{
      overflow: 'auto',
      maxHeight: 'calc(100vh - 160px)',
      '& .MuiToolbar-root': {
        position: 'sticky',
        top: 0,
        zIndex: 13,
        backgroundColor: theme.palette.background.paper,
      },
      '& .MuiTableContainer-root': {
        maxHeight: 'unset',
        overflow: 'auto',
      },
      '& .MuiTable-root': {
        position: 'relative',
      },
      '& .MuiTableCell-head': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.default,
        fontWeight: 'bold',
        fontSize: '0.875rem',
      },
      [`${SORT_LABEL_ROOT}:hover`]: {
        color: theme.palette.info.main,
      },
      [`${SORT_LABEL_ROOT}.Mui-active`]: {
        color: theme.palette.info.main,
      },
      [`${SORT_LABEL_ROOT}.Mui-active .MuiTableSortLabel-icon`]: {
        color: theme.palette.info.main,
      },
      '& thead': {
        position: 'sticky',
        top: 0,
        zIndex: 12,
        '& tr:first-of-type': {
          position: 'sticky',
          top: 0,
          backgroundColor: theme.palette.primary.main,
        },
        '& tr:nth-of-type(2)': {
          position: 'sticky',
          top: '53px',
        },
      },
      '& tbody': {
        position: 'relative',
        zIndex: 11,
      },
    }}
    >
      <MaterialTable
        tableRef={tableRef}
        title={tableTitle}
        columns={columns}
        data={data}
        options={{
          ...options,
          stickyHeader: true,
        }}
        actions={actions}
        isLoading={loading}
        onRowClick={onRowClick}
      />
    </Paper>
  );
  /* eslint-enable */
};

export default OliMaterialTable;
